import { useState, useEffect, useRef } from 'react';

const useElementWidth = () => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const updateWidth = () => {
    if (elementRef.current) {
      const newWidth = elementRef.current.offsetWidth;
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    let delayDebounce;
    const currentElement = elementRef.current;
    updateWidth();

    const resizeObserver = new ResizeObserver(() => {
      delayDebounce = setTimeout(() => {
        updateWidth();
      }, 250);
    });

    if (currentElement) {
      resizeObserver.observe(currentElement);
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
      if (currentElement) {
        resizeObserver.unobserve(currentElement);
      }
    };
  }, []);

  return [width, elementRef];
};

export default useElementWidth;
