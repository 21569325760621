import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/authContext";
import { LocationContext } from "../../context/locationContext";
import { GetAllSystemRoles } from "../../service/administratorsApi";
import api from "../../service/api";
import { getEventControllers } from "../../service/eventsApi";
import { request } from "../../service/requests";
import { GetAllUsers } from "../../service/usersApi";
import {
  ACCESS_POINTS_MODULE,
  API_REQUEST_ERROR_MESSAGE,
  ASCENDING,
  CREDENTIALS_MODULE,
  DEVICE,
  DEVICE_ADMIN,
  EVENTS_MODULE,
  EVENT_VIEWER,
  GET,
  HOLIDAYS_MODULE,
  LOCATION,
  LOCATIONS,
  LOCATIONS_MODULE,
  LOCATION_FILTER,
  OPERATOR,
  PERMISSION_MANAGER,
  PROFILES_MODULE,
  ROLES_MODULE,
  SCHEDULES_MODULE,
  SYSTEM_ADMIN,
  SYSTEM_ROLES_MODULE,
  USERS_MODULE
} from "../../utility/constants";
import { formatTime } from "../../utility/helper";
import Select from "../select";

const SelectItems = (props) => {
  const {
    onChange,
    name,
    showToaster,
    selectedItems,
    disabled,
    single,
    isValid,
    helperText,
    value,
    selectedCredentials,
    setHasSelected,
    required,
    module,
    id,
    handlePermissions,
    selectedProfiles,
    truncate,
    limitChips
  } = props;
  const { t } = useTranslation();

  const [isLoadingSelect, setIsLoadingSelect] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [defaultValue, setDefaultValue] = useState(selectedItems);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const { state } = useContext(AuthContext);
  const { administrator } = state;
  const { locations, areaLocations } = administrator;

  const { state : locationState }  = useContext(LocationContext);
  const { selectedLocationIds }    = locationState;

  const isLocations = name === LOCATIONS_MODULE || name === LOCATION_FILTER;
  const isCredential = name === CREDENTIALS_MODULE;
  const isUser = name === USERS_MODULE;
  const isSystemRoles = name === SYSTEM_ROLES_MODULE;

  const getModuleUrl = useCallback((query, getAllApi, searchApi) => {
    return query === "" ? getAllApi : searchApi;
  }, []);

  const getUrl = useCallback(
    (query) => {
      if (name === CREDENTIALS_MODULE) {
        return getModuleUrl(
          query,
          api.CREDENTIALS_ACTIVE,
          api.CREDENTIALS_SEARCH_AVAILABLE
        );
      } else if (name === ROLES_MODULE) {
        return getModuleUrl(query, api.ROLES, api.ROLES_BY_NAME);
      } else if (name === SCHEDULES_MODULE) {
        return getModuleUrl(query, api.SCHEDULES, api.SCHEDULES_BY_NAME);
      } else if (name === HOLIDAYS_MODULE) {
        return getModuleUrl(query, api.HOLIDAYS, api.HOLIDAYS_BY_NAME);
      } else if (name === PROFILES_MODULE) {
        return getModuleUrl(query, api.PROFILES, api.PROFILES_BY_NAME);
      } else {
        return getModuleUrl(
          query,
          api.ACCESS_POINTS,
          api.ACCESS_POINTS
        );
      }
    },
    [getModuleUrl, name]
  );

  const getParams = useCallback(
    (query, pageNumber) => {
      if (name === CREDENTIALS_MODULE) {
        return {
          credentialNumber: query,
          page: pageNumber,
          sort: `credentialNumber, ${ASCENDING}`,
        };
      } else if (name === USERS_MODULE) {
        return {
          search              : query,
          sort                : `lastName,${ASCENDING}`,
          size                : 20,
          page                : pageNumber - 1,
          briefRepresentation : true
        };
      } else if (name === LOCATION) {
        return {
          keyword: query,
        };
      } else if (name === ACCESS_POINTS_MODULE) {
        return {
          name: query,
          page: pageNumber,
          sort: `name,${ASCENDING}`
        };
      } else if (name === EVENTS_MODULE) {
        return {
          keyword: query,
          size   : 30,
          page   : pageNumber,
          sort   : `${DEVICE},${ASCENDING}`
        };
      } else {
        return {
          name: query,
          page: pageNumber,
          sort: `name, ${ASCENDING}`,
        };
      }
    },
    [name]
  );

  const getFormattedItem = useCallback(
    (item, index) => {
      let link = item?._links?.self.href;

      if (name === CREDENTIALS_MODULE) {
        return {
          id: item.credentialId,
          name: item.credentialNumber,
          description:
            formatTime(item.validFrom) + "-" + formatTime(item.validUntil),
        };
      } else if (name === USERS_MODULE) {
        return {
          id: item.user.id,
          name: item.user.lastName + ", " + item.user.firstName,
          description:
            formatTime(item.user.attributes.validFrom) + "-" + formatTime(item.user.attributes.validUntil),
        };
      } else if (name === ROLES_MODULE) {
        return {
          id: item.roleId,
          name: item.name,
        };
      } else if (name === SCHEDULES_MODULE) {
        return {
          link: link,
          id: item.scheduleId,
          name: item.name,
        };
      } else if (name === HOLIDAYS_MODULE) {
        return {
          link: link,
          name: item.name,
          id: item.holidayId,
        };
      } else if (name === LOCATIONS) {
        return locations?.map((location) => {
          return {
            id: location.locationId,
            name: location.name,
          };
        });
      } else if (name === SYSTEM_ROLES_MODULE) {
        return {
          id: item.id,
          name: item.name,
        };
      } else if (name === PROFILES_MODULE) {
        return {
          id: item.profileId,
          name: item.name,
          description:
            formatTime(item.validFrom) + "-" + formatTime(item.validUntil),
        };
      } else if (name === EVENTS_MODULE) {
        return {
          id: index,
          name: item,
        };
      } else {
        return {
          name: `${item.controller.name}:${item.name}`,
          id: item.id,
          serialNumber: item.controller.serialNumber,
        };
      }
    },
    [locations, name]
  );

  const getResponseItems = useCallback(
    (data) => {
      if (name === CREDENTIALS_MODULE) {
        return data._embedded.credentials;
      } else if (name === ROLES_MODULE) {
        return data._embedded.roles;
      } else if (name === SCHEDULES_MODULE) {
        return data._embedded.schedules;
      } else if (name === HOLIDAYS_MODULE) {
        return data._embedded.holidays;
      } else if (name === SYSTEM_ROLES_MODULE) {
        return data._embedded.systemRoles;
      } else if (name === PROFILES_MODULE) {
        return data._embedded.profiles;
      } else {
        return data.accessPoints;
      }
    },
    [name]
  );

  const getLabel = () => {
    if (name === CREDENTIALS_MODULE) {
      return "addCredentials";
    } else if (name === USERS_MODULE) {
      return "users";
    } else if (name === ROLES_MODULE) {
      return "roles";
    } else if (name === SCHEDULES_MODULE) {
      return "schedules";
    } else if (name === HOLIDAYS_MODULE) {
      return "holidays";
    } else if (name === ACCESS_POINTS_MODULE) {
      return "accessPoints";
    } else if (name === SYSTEM_ROLES_MODULE) {
      return "role";
    } else if (name === PROFILES_MODULE) {
      return "addProfile";
    } else if (name === EVENTS_MODULE) {
      return "controller";
    } else {
      return 'location';
    }
  };

  const getDefaultSystemRoles = useCallback(async () => {
    const systemRoles = await GetAllSystemRoles();
    const defaultRoles = systemRoles.filter(
      (role) =>
        role.name === SYSTEM_ADMIN ||
        role.name === DEVICE_ADMIN ||
        role.name === OPERATOR ||
        role.name === PERMISSION_MANAGER ||
        role.name === EVENT_VIEWER
    );

    return defaultRoles.filter((item) => item !== undefined);
  }, []);

  const arrangeUseSearchItems = useCallback(
    async (newItems, pageNumber, query) => {
      if (isSystemRoles) {
        const defaultSystemRoles = await getDefaultSystemRoles(pageNumber);
        const createdSystemRoles = newItems
          .filter(
            (role) =>
              role.name !== SYSTEM_ADMIN &&
              role.name !== DEVICE_ADMIN &&
              role.name !== OPERATOR &&
              role.name !== PERMISSION_MANAGER &&
              role.name !== EVENT_VIEWER
          )
          .filter((item) => item !== undefined);
        const systemRolesList =
          query === ""
            ? defaultSystemRoles.concat(createdSystemRoles)
            : newItems;
        const updatedSystemRoles =
          pageNumber === 0 ? systemRolesList : newItems.concat(systemRolesList);
        setItems(updatedSystemRoles);
      } else {
        setItems((prevItems) =>
          pageNumber === 0 ? newItems : prevItems.concat(newItems)
        );
      }
    },
    [getDefaultSystemRoles, isSystemRoles]
  );

  const getItems = useCallback(async () => {
    var totalElements = 100;
    var totalPages = 1;
    var responseItems = [];

    if (name === SYSTEM_ROLES_MODULE) {
      //Note: there is no pagination in system roles in keycloack
      responseItems = await GetAllSystemRoles(searchQuery);
      totalElements = responseItems.length;
    } else if (name === USERS_MODULE) {
      const response = await GetAllUsers(getParams(searchQuery, currentPageNumber));
      totalElements = response.data.page.totalElements;
      totalPages = response.data.page.totalPages;
      responseItems = response.data.users;
    } else if (name === EVENTS_MODULE) {
      const params = getParams(searchQuery, currentPageNumber);
      const response = await getEventControllers(params);
      
      responseItems = response.data._embedded.devices;
      totalElements = response.data.page.totalElements;
      totalPages = response.data.page.totalPages;
    } else {
      const response = await request({
        url: getUrl(searchQuery),
        method: GET,
        params: getParams(searchQuery, currentPageNumber),
      });

      totalElements = response.data.page.totalElements;
      totalPages = response.data.page.totalPages;
      responseItems = getResponseItems(response.data);
    }
    return { responseItems, totalElements, totalPages };
  }, [
    currentPageNumber,
    searchQuery,
    name,
    getResponseItems,
    getParams,
    getUrl,
  ]);

  const getDefaultValue = () => {
    if (isCredential) {
      return selectedCredentials;
    } else if (isUser && defaultValue[0]?.userId) {
      return [
        {
          id: defaultValue[0]?.userId,
          name: defaultValue[0]?.name,
          description: defaultValue[0]?.description,
        },
      ];
    } else if (name === PROFILES_MODULE) {
      return selectedProfiles;
    } else if (isLocations && locations.length === 1) {
      return [
        {
          id: locations[0]?.locationId,
          name: locations[0]?.name,
        },
      ];
    } else {
      return defaultValue;
    }
  };

  const searchHandler = useCallback((query, pageNumber) => {
    setSearchQuery(query);
    setCurrentPageNumber(pageNumber);
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoadingSelect(true);
    setError(false);
    try {
      const { responseItems, totalElements, totalPages } = await getItems();
      const newItems = responseItems.map((item, index) => getFormattedItem(item, index));
      await arrangeUseSearchItems(newItems, currentPageNumber, searchQuery);
      setHasMore(totalElements > 0 && currentPageNumber < totalPages - 1);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return;
      }
      setError(true);
      showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
    }
    setIsLoadingSelect(false);
  }, [ searchQuery, currentPageNumber, getFormattedItem, getItems, arrangeUseSearchItems, showToaster, t]);

  // effect react on changes when items fetched from backend are being selected
  useEffect(() => {
    // The above code is checking if the variable "isLocations" is false and either the user has a 
    // read permission for the given module name, or if the module is System Role 
    if (!isLocations && (handlePermissions(name, GET) || isSystemRoles)) {
      fetchData();

      if (setHasSelected) {
        setHasSelected(false);
      }
    }
  }, [
    fetchData,
    isLocations,
    setHasSelected,
    handlePermissions,
    isSystemRoles,
    name,
    selectedLocationIds
  ]);

  // effect react on changes when locations are being selected
  useEffect(() => {
    if (isLocations) {
      setItems(areaLocations);

      if (setHasSelected) {
        setHasSelected(false);
      }
    }
  }, [
    isLocations,
    setHasSelected,
    areaLocations,
  ]);

  // populate async items passed from the parent component
  useEffect(() => {
    setDefaultValue(selectedItems);
  }, [selectedItems])
  
  return (
    <Select
      id={id}
      single={single}
      disabled={disabled}
      search={searchHandler}
      selectFrom={name}
      label={getLabel()}
      onChange={onChange}
      defaultValue={getDefaultValue()}
      isValid={isValid}
      helperText={helperText}
      value={value}
      required={required}
      module={module}
      handlePermissions={handlePermissions}
      items={items}
      error={error}
      hasMore={hasMore}
      isLoadingSelect={isLoadingSelect}
      currentPageNumber={currentPageNumber}
      searchQuery={searchQuery}
      truncate={truncate}
      limitChips={limitChips}
    />
  );
};

export default SelectItems;
