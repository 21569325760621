import { Box, Container, NoSsr, Paper, Typography } from "@material-ui/core";
import axios from "axios";
import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { LocationContext } from "../../context/locationContext";
import { ChipDetailsSkeleton, ChipListSkeleton } from "../../pages/dashboard/dashboard-skeleton";
import { moduleName } from '../../pages/dashboard/utils';
import api from "../../service/api";
import { request } from "../../service/requests";
import { GetAllUsers } from "../../service/usersApi";
import { ACCESS_CHECK, ACCESS_DENIED, API_REQUEST_ERROR_MESSAGE, ASCENDING, DASHBOARD_CHIP, DATE_CREATED, DATE_FORMAT_YYYY_MM_DD, DATE_TIME_FORMAT, DATETIME_FORMAT, DAY, DESCENDING, GET, NAME, OFFLINE, USERNAME } from "../../utility/constants";
import useStyles from "./styles";
import { getEventListCount } from "../../service/eventsApi";
import { convertToUTC } from "../../utility/helper";

const EnhancedList = (props) => {
  const classes = useStyles();
  const { items, lastItemElement, handleDetailClick, error, name} = props;

  return (
    <>
      <NoSsr>
        <Box>{error && 'ERROR'}</Box>
        <Box className={classes.items}>
        {
          items.map((item, index) => {
            let elementRecordId = item.detailId;
            
            if (items.length === index + 1) {
              return (
                <Box id={`${elementRecordId}`} ref={lastItemElement} key={`Select-Last-Item-` + index } className={classes.itemBox} onClick={() => handleDetailClick(item.detailId, name)}>
                  <Box onClick={() => handleDetailClick(item.detailId, name)}>
                    <Box className={clsx({ [classes.hoverBold]: item.detailName })}>
                      <span id={`${elementRecordId}Name`}>{item.detailName}</span>
                    </Box>
                  </Box>
                </Box>
              )
            } else {
              return (
                <Box id={`${elementRecordId}`} key={`Select-Item-` + index} className={classes.itemBox} onClick={() => handleDetailClick(item.detailId, name)}>
                  <Box onClick={() => handleDetailClick(item.detailId, name)}>
                    <Box className={clsx({ [classes.hoverBold]: item.detailName })}>
                      <span id={`${elementRecordId}Name`}>{item.detailName}</span>
                    </Box>
                  </Box>
                </Box>
              )
            }
          })
        }
        </Box>
      </NoSsr>
    </>
  )
}

const ChipDetail = (props) => {
  const { pageHandler, isLoadingScroll, error, items, hasMore, currentPageNumber, name, handleDetailClick } = props;

  const observer = useRef();
  const lastItemElement = useCallback(node => {
    if (isLoadingScroll) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        pageHandler(currentPageNumber + 1);
      }
    })
    if (node) observer.current.observe(node)
  }, [isLoadingScroll, hasMore, pageHandler, currentPageNumber] )
  
  return (
    <EnhancedList
      handleDetailClick={handleDetailClick}
      error={error}
      isLoading={isLoadingScroll}
      items={items}
      lastItemElement={lastItemElement}
      name={name}
    />
  )
}

const ChipDetailList = (props) => {
  const { detailsCount, detailsName, showToaster } = props;

  const { t }   = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = history.location;
  
  const { state : locationState }                        = useContext(LocationContext);
  const { selectedLocationIds }                          = locationState;
  
  const [isLoadingScroll, setIsLoadingScroll]       = useState(false);
  const [isLoading, setIsLoading]                   = useState(false);
  const [error, setError]                           = useState(false);
  const [items, setItems]                           = useState([]);
  const [hasMore, setHasMore]                       = useState(false);
  const [currentPageNumber, setCurrentPageNumber]   = useState(0);
  const [currentDetailsName, setCurrentDetailsName] = useState('');
  
  const [ isCompactView, setIsCompactView ]     = useState(false);
  const [ windowDimension, setWindowDimension ] = useState({
    windowWidth : window.innerWidth
  });

  const handleDetailClick = (id, chipName) => {
    const redirectTo = moduleName(chipName);

    history.push(`/${redirectTo}/view/${id}`, { from: pathname })
  }

  const getUrl = useCallback((detailsName) => {
    switch (detailsName) {
      case DASHBOARD_CHIP.ACCESS_DENIED_CHIP:
        return api.EVENTS;
      case DASHBOARD_CHIP.AVAILABLE_CREDENTIAL_CHIP:
        return api.CREDENTIALS_ACTIVE;
      case DASHBOARD_CHIP.INVALID_CREDENTIAL_CHIP:
        return api.CREDENTIALS_INVALID;
      default:
        return api.CONTROLLERS;
    }
  }, []);

  const getParams = useCallback((detailsName, currentPageNumber) => {
    switch (detailsName) {
      case DASHBOARD_CHIP.ACCESS_DENIED_CHIP:
        return {
          types       : ACCESS_CHECK,
          subTypes    : ACCESS_DENIED,
          until       : moment().add(1, DAY).startOf(DAY).format(DATETIME_FORMAT.BASIC_DATE),
          size        : 50,
          page        : currentPageNumber + 1,
          sort        : `${DATE_CREATED},${DESCENDING}`,
          locationIds : selectedLocationIds.toString()
        };
      case DASHBOARD_CHIP.AVAILABLE_CREDENTIAL_CHIP:
        return {
          page : currentPageNumber,
          size : 50,
          date : moment().format(DATE_FORMAT_YYYY_MM_DD)
        };
      case DASHBOARD_CHIP.INVALID_CREDENTIAL_CHIP:
        return {
          page           : currentPageNumber,
          size           : 50,
          validUntilDate : moment().format(DATE_FORMAT_YYYY_MM_DD),
          validFromDate  : moment().format(DATE_FORMAT_YYYY_MM_DD)
        };
      case DASHBOARD_CHIP.USER_WITH_INVALID_CHIP:
        return {
          page                : currentPageNumber,
          size                : 50,
          sort                : `${USERNAME},${ASCENDING}`,
          validUntil          : moment().format(DATE_FORMAT_YYYY_MM_DD),
          briefRepresentation : true
        };
      default:
        return {
          status      : OFFLINE,
          page        : currentPageNumber,
          size        : 50,
          sort        : `${NAME},${ASCENDING}`,
          locationIds : selectedLocationIds.toString()
        };
    }
  }, [selectedLocationIds]);

  const getFormattedItem = useCallback((detailsName, item) => {
    switch (detailsName) {
      case DASHBOARD_CHIP.ACCESS_DENIED_CHIP:
        const detail = item.controller ? `${item.controller.name}` : `${item.type}`;
        const subDetail = item.credential ? `${item.credential.credentialNumber}` : `${item.subType}`;

        return {
          detailId   : item.eventId,
          detailName : `${detail}: ${subDetail} - ${convertToUTC(item.dateCreated).format(DATE_TIME_FORMAT)}`
        };
      case DASHBOARD_CHIP.AVAILABLE_CREDENTIAL_CHIP:
      case DASHBOARD_CHIP.INVALID_CREDENTIAL_CHIP:
        return {
          detailId    : item.credentialId,
          detailName  : item.credentialNumber
        };
      case DASHBOARD_CHIP.USER_WITH_INVALID_CHIP:
        return {
          detailId    : item.user.id,
          detailName  : `${item.user.lastName}, ${item.user.firstName}`
        };
      default:
        return {
          detailId  : item.controllerId,
          detailName: item.name
        };
    }
  }, []);

  const getResponseItems = useCallback((detailsName, response) => {
    switch (detailsName) {
      case DASHBOARD_CHIP.ACCESS_DENIED_CHIP:
        return response.data.events;
      case DASHBOARD_CHIP.AVAILABLE_CREDENTIAL_CHIP:
        return response.data._embedded.credentials;
      case DASHBOARD_CHIP.INVALID_CREDENTIAL_CHIP:
        return response.data._embedded.credentials;
      case DASHBOARD_CHIP.USER_WITH_INVALID_CHIP:
        return response.data.users;
      default:
        return response.data.controllers;
    }
  }, []);

  const getItems = useCallback(async (detailsName, currentPageNumber) => {
    let totalElements = 100;
    let totalPages = 1;
    let responseItems = [];

    let response = '';
    if (detailsName === DASHBOARD_CHIP.USER_WITH_INVALID_CHIP) {
      response = await GetAllUsers(getParams(detailsName, currentPageNumber));

      totalElements = response.data.page.totalElements;
      totalPages = response.data.page.totalPages;
    } else if (detailsName === DASHBOARD_CHIP.ACCESS_DENIED_CHIP) {
      const params = getParams(detailsName, currentPageNumber);

      response = await request({
        url   : getUrl(detailsName),
        method: GET,
        params: params
      });

      const totalResponse = await getEventListCount(params);
      totalElements = totalResponse.page.totalElements;
      totalPages = totalResponse.page.totalPages;
    } else {
      response = await request({
        url   : getUrl(detailsName),
        method: GET,
        params: getParams(detailsName, currentPageNumber)
      });

      totalElements = response.data.page.totalElements;
      totalPages = response.data.page.totalPages;
    }

    responseItems = getResponseItems(detailsName, response);

    return { responseItems, totalElements, totalPages };
  }, [
    getResponseItems,
    getParams,
    getUrl,
  ]);

  const arrangeItems = useCallback(async (newItems, pageNumber) => {
    setItems((prevItems) =>
      pageNumber === 0 ? newItems : prevItems.concat(newItems)
    );
  }, []);

  const fetchData = useCallback(async (pageNumber) => {
    if (!selectedLocationIds) {
      return;
    }
    setIsLoadingScroll(true);
    setError(false);

    try {
      const { responseItems, totalElements, totalPages } = await getItems(detailsName, pageNumber);

      const newItems = responseItems.map((item) => {
        return getFormattedItem(detailsName, item);
      });
      
      await arrangeItems(newItems, pageNumber);
      setHasMore(totalElements > 0 && pageNumber < totalPages - 1);
    } catch (e) {
      console.log("error", e);
      if (axios.isCancel(e)) {
        return;
      }
      setError(true);
      showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
    }

    setIsLoadingScroll(false);
    setIsLoading(false);
  }, [detailsName, selectedLocationIds, getFormattedItem, getItems, arrangeItems, showToaster, t]);

  useEffect(() => {
    setCurrentPageNumber(0);
    setCurrentDetailsName(detailsName);
  }, [selectedLocationIds, detailsName]);

  useEffect(() => {
    if (currentDetailsName === detailsName && currentPageNumber > 0) {
      fetchData(currentPageNumber);
    } else if (currentDetailsName !== detailsName) {
      setIsLoading(true);
      fetchData(0);
    }
  }, [currentPageNumber, fetchData, currentDetailsName, detailsName]);

  const pageHandler = useCallback((pageNumber) => {
    setCurrentPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimension);
    if (windowDimension.windowWidth < 1280 && windowDimension.windowWidth > 960) {
      setIsCompactView(true);
    } else {
      setIsCompactView(false);
    }
    return() => {
      window.removeEventListener('resize', updateWindowDimension);
    }
  }, [windowDimension]);

  const updateWindowDimension = () => {
    setWindowDimension({
      windowWidth  : window.innerWidth
    });
  }

  return (
    <>
      {
        isLoading ?
          <ChipDetailsSkeleton/> :
          <Container className={classes.chartContainer}>
          <Paper id="dashboardChipDetailsContainer" className={classes.paper}>
            <Box className={classes.chipDetailsContainer}>
              <Box className={classes.chartName}>
                <Box className={classes.header}>
                  <Typography><span id="dashboardChipDetailsTitle">{t(detailsName)}</span></Typography>
                  <Box className={classes.chipDetailsNumberCount}>
                    <Typography>
                      {t('totalCount')} <span id="dashboardChipDetailsCount">{detailsCount}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
                {
                  isLoading ?
                    <ChipListSkeleton isCompactView={isCompactView}/>
                  :
                    <Box className={isCompactView ? classes.chipListContainer : classes.chipDetailsTable}>
                      <Box className={classes.chipDetailsContent}>
                        <ChipDetail
                          name={detailsName}
                          handleDetailClick={handleDetailClick}
                          pageHandler={pageHandler}
                          isLoadingScroll={isLoadingScroll}
                          items={items}
                          error={error}
                          hasMore={hasMore}
                          currentPageNumber={currentPageNumber}
                        />
                      </Box>
                    </Box>
                }
            </Box>
          </Paper>
        </Container>
      }
      
    </>
  );
}

export default ChipDetailList;