import { Box, Table, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { EMPTY_TABLE_MESSAGE } from "../../utility/constants";
import useStyles from "../enhanced-empty/styles";

const EnhancedEmptyTable = (props) => {
  const { label } = props;

  const translationKey = EMPTY_TABLE_MESSAGE[label] ?? 'no-result.title';

  const { t }   = useTranslation();
  const classes = useStyles();
  return (
    <Table data-testid={`empty-table`} className={classes.mainTable} aria-labelledby="emptyTable" size={'medium'}>
      <Box className={classes.emptyTableContainer}>
        <Typography variant="h5" gutterBottom className={classes.emptyTableTitle}>
          {t(translationKey)}
        </Typography>
      </Box>
    </Table>
  );
}

export default EnhancedEmptyTable;