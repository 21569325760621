import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth     : '100%'
  },
  paper: {
    marginTop          : theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop        : theme.spacing(0)
    },
    '& p.MuiTypography-root.MuiTypography-body2':{
      color: "black"
    },
    '& input#userPersonalNumber.Mui-disabled':{
      color: "black"
    },
    '& textarea#userLastName.Mui-disabled':{
      color: "black"
    },
    '& textarea#userFirstName.Mui-disabled':{
      color: "black"
    },
    '& input#userValidFrom.Mui-disabled':{
      color: "black"
    },
    '& input#userValidUntil.Mui-disabled':{
      color: "black"
    },
    '& input#userViewPin.Mui-disabled':{
      color: "black"
    }
  },
  userDetailContainer: {
    borderRadius: theme.spacing(1),
    padding     : theme.spacing(3), 
  },
  userDetailPictureSection: {
    display       : 'flex', 
    alignItems    : 'flex-start', 
    justifyContent: 'center',
    height        : theme.spacing(6),
    marginRight   : theme.spacing(2)
  },
  detailsField: {
    display      : 'flex',
    flexDirection: 'column'
  },
  pinVisibilityIcon: {
    padding   : theme.spacing(0),
    marginLeft: theme.spacing(1)
  },
  smallSectionContainer: {
    display      : 'flex',
    width        : '100%',
    flexDirection: 'column'
  },
  mediumSectionContainer: {
    display: 'flex',
    width  : '100%',
  },
  largeSectionContainer: {
    display: 'flex',
    width  : '100%',
  },
  smallDetailsContainer: {
    width       : '100%',
    marginBottom: theme.spacing(3),
    marginRight : theme.spacing(0)
  },
  mediumDetailsContainer: {
    minWidth   : theme.spacing(42),
    marginRight: theme.spacing(3)
  },
  largeDetailsContainer: {
    minWidth   : theme.spacing(42),
    marginRight: theme.spacing(3)
  },
  smallEntityContainer: {
    width: '100%',
  },
  mediumEntityContainer: {
    flexGrow: 1
  },
  largeEntityContainer: {
    flexGrow: 1,
    display : 'flex'
  },
  smallCredentialsContainer: {
    width       : '100%',
    marginBottom: theme.spacing(3)
  },
  mediumCredentialsContainer: {
    width       : '100%',
    marginBottom: theme.spacing(3),
  },
  largeCredentialsContainer: {
    marginRight: theme.spacing(3),
    width      : theme.spacing(50),
  },
  smallProfilesContainer: {
    width: '100%',
  },
  mediumProfilesContainer: {
    width: '100%',
  },
  largeProfilesContainer: {
    flexGrow: 1
  },
  smallRolesContainer: {
    width       : '100%',
    marginBottom: theme.spacing(3)
  },
  mediumRolesContainer: {
    width       : '100%',
    marginBottom: theme.spacing(3)
  },
  largeRolesContainer: {
    width      : theme.spacing(50),
    marginRight: theme.spacing(3),
  },
  smallChipLayout: {
    display      : 'flex',
    flexWrap     : 'wrap',
    gap          : theme.spacing(1)
  },
  mediumChipLayout: {
    display      : 'flex',
    flexWrap     : 'wrap',
    gap          : theme.spacing(2)
  },
  largeChipLayout: {
    display      : 'flex',
    flexDirection: 'column',
    flexWrap     : 'wrap',
    gap          : theme.spacing(1)
  },
  longComplexChip: {
    minWidth: theme.spacing(50),
    maxWidth: theme.spacing(50)
  },
  shortComplexChip: {
    minWidth: theme.spacing(37.5),
    maxWidth: theme.spacing(37.5)
  },
  userDetailTitle: {
    marginBottom: theme.spacing(2),
    fontSize    : theme.spacing(2),
    fontWeight  : '700'
  },
  userDetail: {
    marginBottom: theme.spacing(1),
    fontSize    : theme.spacing(1.75)
  },
  noAttachedEntityNote: {
    fontStyle: 'italic',
    color: colors.SESAMSEC_SYS_LIGHT_ON_SURFACE_VARIANT
  }, 
  actionContainer: {
    display       : 'flex',
    justifyContent: 'flex-end'
  }, 
  textDivider: {
    fontSize   : theme.spacing(1.75),
    marginLeft : theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  compactContainer: {
    display       : 'flex',
    justifyContent: 'space-between'
  },
  detailsAndEntites: {
    display      : 'flex', 
    flexDirection: 'column'
  },
  spread: {
    display: 'flex'
  },
  compactActionContainer: {
    display       : 'flex', 
    justifyContent: 'center'
  },
  smallIcon: {
    fontSize: theme.spacing(6),
    color   : colors.SESAMSEC_SYS_LIGHT_OUTLINE_SILENT
  },
  smallIconContainer: {
    marginRight : theme.spacing(2), 
    height      : theme.spacing(6), 
    width       : theme.spacing(6)
  },
  largeIcon: {
    fontSize: theme.spacing(6),
    color   : colors.SESAMSEC_SYS_LIGHT_OUTLINE_SILENT
  },
  switchBase: {
    width  : 52, 
    height : 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin : 4,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color    : '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1E1E1F1F',
          opacity        : 1,
          border         : 0,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#fff',
          position       : 'relative',
          width          : 24, 
          height         : 24, 
          '&::before': {
            content        : '""',
            position       : 'absolute',
            top            : '50%',
            left           : '50%',
            transform      : 'translate(-50%, -50%)',
            width          : theme.spacing(2),
            height         : theme.spacing(2),
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%231E1E1F80" viewBox="0 0 24 24"><path d="M9 16.2l-3.5-3.5L4 14.2 9 19.2l11-11L18.6 7l-9.6 9.6z"/></svg>')`, // Custom check SVG icon
            backgroundSize : 'cover',
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&:not(.Mui-checked)': {
        '& .MuiSwitch-thumb': {
          backgroundColor: '#1E1E1F80',
          position       : 'relative',
          width          : 24,
          height         : 24, 
          '&::before': {
            content        : '""',
            position       : 'absolute',
            top            : '50%',
            left           : '50%',
            transform      : 'translate(-50%, -50%)',
            width          : theme.spacing(2),
            height         : theme.spacing(2),
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23FFFFFF80" viewBox="0 0 24 24"><path d="M18.3 5.71L12 12l6.3 6.29-1.42 1.42L12 13.42l-6.29 6.29-1.42-1.42L10.59 12 4.29 5.71 5.71 4.29 12 10.59l6.29-6.3z"/></svg>')`, // Custom X SVG icon
            backgroundSize : 'cover', 
          },
        },
        '& + .MuiSwitch-track': {
          backgroundColor: '#1E1E1F1F',
          border         : '2px solid #1E1E1F80',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#ccc', 
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing      : 'border-box',
      width          : 24,
      height         : 24, 
      backgroundColor: '#1E1E1F80',
      boxShadow      : 'none', 
    },
    '& .MuiSwitch-track': {
      borderRadius   : 100, 
      backgroundColor: '#E9E9EA',
      opacity        : 1,
    },
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
  },
  calendarTextField: {
    '& .MuiInputBase-root' : {
      padding            : '0'
    },
  },
  containerCredentials: {  
    border          : 'thin',
    borderStyle     : 'solid',
    borderRadius    : '4px',
    borderColor     : '#c4c4c4',
    height          : '300px',
    marginTop       : theme.spacing(-1),
  },
  fieldsetLegend:{
    color           : '#767779'
  },
  hrDivider:  {
    border    : 0,
    borderTop : '1px dotted #767779',
    float     : 'right',
    width     : '100%',
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding         : theme.spacing(2),
    textAlign       : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  view: {
    marginTop     : theme.spacing(4),
    paddingLeft   : theme.spacing(1),
    paddingRight  : theme.spacing(1),
  },
  skeleton: {
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center',
    height          : '100%',
  },
  location: {
    paddingLeft: theme.spacing(1)
  },
  pin: {
    marginBottom: '4px'
  },
  pinDescription: {
    color       : colors.SECONDARY_TEXT,
    fontSize    : '12px',
    lineHeight  : '16px',
    marginLeft  : '16px',
    marginRight : '16px'
  },
  pinDescriptionError: {
    color       : colors.ERROR,
    fontSize    : '12px',
    lineHeight  : '16px',
    marginLeft  : '16px',
    marginRight : '16px'
  },
  contentHeader: {
    marginBottom : theme.spacing(2),
    marginTop    : theme.spacing(3)
  },
  reminderIcon: {
    marginRight: theme.spacing(1)
  },
  titleBox: {
    alignSelf : 'center'
  },
  userActionButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '& .MuiButton-startIcon' : {
      marginRight: theme.spacing(0.5)
    },
    borderRadius : theme.spacing(1),
    minHeight    : theme.spacing(5),
    width        : 'auto', 
    whiteSpace   : 'nowrap',
    marginLeft   : theme.spacing(1.25)
  },
  buttonContainedVariant: {
    color           : colors.SESAMSEC_SYS_LIGHT_ON_PRIMARY,
    backgroundColor : colors.SESAMSEC_SYS_LIGHT_PRIMARY,
    paddingLeft     : theme.spacing(2),
    paddingRight    : theme.spacing(3),
  },
  buttonTextVariant: {
    color        : colors.SESAMSEC_SYS_LIGHT_PRIMARY,
    paddingLeft  : theme.spacing(1.5),
    paddingRight : theme.spacing(2),
  },
  editUserButtonContainer: {
    alignContent: 'center'
  },
  additionalInfoBox: {
    marginTop: theme.spacing(2),
    width    : '100%'
  }
}));