import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actions: {
    alignItems     : 'center',
    display        : 'flex',
    gap            : theme.spacing(1),
    justifyContent : 'center',
    paddingLeft    : theme.spacing(1),
    paddingRight   : theme.spacing(1)
  },
  text: {
    height    : '20px',
    transform : 'none',
    width     : '100%'
  },
  icon: {
    borderRadius: '4px',
    height      : '26px',
    width       : '34px'
  }
}));