import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  reminderContainer: {
    display     : 'flex',
    alignItems  : 'center',
    padding     : theme.spacing(2),
    borderRadius: theme.spacing(1),
    width       : '100%'
  },
  yellowVariant: {
    backgroundColor: colors.SESAMSEC_SYS_LIGHT_TERTIARY_CONTAINER,
    color          : colors.SESAMSEC_SYS_LIGHT_ON_TERTIARY_CONTAINER,
  },
  reminderText: {
    fontSize: theme.spacing(1.75)
  },
  
}));