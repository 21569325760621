import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  popOverPaper: {
    display       : 'flex',
    width         : theme.spacing(40),
    padding       : theme.spacing(3),
    flexDirection : 'column',
    justifyContent: 'left',
    alignItems    : 'left',
    gap           : theme.spacing(1),
    borderRadius  : theme.spacing(1),
    boxShadow     : 2,
    position      : 'relative'
  },
  closeButton: {
    position : 'absolute', 
    right    : theme.spacing(1.875), 
    top      : theme.spacing(1.875), 
    color    : colors.SESAMSEC_SYS_LIGHT_ON_SURFACE_SILENT,
    cursor   : 'pointer'
  },
  titleContainer: {
    maxWidth: theme.spacing(31.25)
  },
  title: {
    fontSize  : theme.spacing(2),
    fontWeight: 'bold'
  },
  description: {
    fontSize: theme.spacing(1.75)
  }
}))