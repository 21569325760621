import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	modalLoading: {
		alignItems    : 'center',
		display       : 'flex',
		justifyContent: 'center'
	},
	modalContent:{
		alignItems     : 'center',
		borderRadius   : theme.spacing(2),
		display        : 'flex',
		height         : theme.spacing(23.75),
		justifyContent : 'center',
		padding        : theme.spacing(2),
		width          : 'fit-content',
		'&:focus'      : {
			outline: 'none'
		},

	},
	chameleonContainer:{
		alignItems    : 'center',
		display       : 'flex',
		height        : theme.spacing(2),
		justifyContent: 'center'
	},
	chameleon: {
		height: theme.spacing(22.5)
	}
}))

export default useStyles;