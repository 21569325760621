import { DELETE, GET, PATCH, POST, PUT, USER_PROVIDER } from "../utility/constants";
import api from "./api";
import { multipleRequest, request } from "./requests";

const AUTH_BASE_PATH = window.KEYCLOAK_URL;
const USERS_AUTH_API = AUTH_BASE_PATH + "/realms/" + window.KEYCLOAK_REALM + "/users";
const USERS_RESOURCE_API = AUTH_BASE_PATH + "/admin/realms/" + window.KEYCLOAK_REALM + "/users";

export const GetAllUsers = async (params) => {
  const usersResponse = await request({
    url   : USERS_AUTH_API,
    method: GET,
    params: params
  });

  return usersResponse;
}

export const GetUsersWithCredentials = async (keyword) => {
  const response = await request({
    url   : api.USER_WITH_CREDENTIALS,
    method: GET,
    params: {
      lastName  : keyword,
    },
  });

  const { content } = response.data;
  return content.map(user => {
    return {
      id          : user.userId,
      label       : `${user.lastName}, ${user.firstName}`,
      credentials : []
    }
  });
}

export const GetUserByPersonalNumber = async (personalNumber) => {
  const usersResponse = await request({
    url   : USERS_AUTH_API,
    method: GET,
    params: {
      username: personalNumber,
      exact: true
    }
  });

  return usersResponse.data;
}

export const getAllUsersPage = async (values) => {
  const { search, sort, size, page } = values;

  const usersResponse = await request({
    url   : USERS_AUTH_API,
    method: GET,
    params: {
      search : search,
      sort   : sort,
      size   : size,
      page   : page - 1,
    }
  });

  const pageElement = usersResponse.data.page;
  const users = usersResponse.data.users.map((userItem) => {
    const { user, roles, location, credentials }            = userItem;
    const { id, username, firstName, lastName, attributes } = user;
    const { validFrom, validUntil, pin, provider }          = attributes;

    const locationReq    = getLocation(location);
    const rolesReq       = getRoles(roles);
    const credentialsReq = getCredentials(credentials);
    const pinReq         = pin === undefined || pin.length === 0 ? '' : pin[0];
    const source         = provider?.includes(USER_PROVIDER.LDAP) ? USER_PROVIDER.LDAP : USER_PROVIDER.LOCAL;

    return {
      personalNumber : username,
      userId         : id,
      name           : `${lastName}, ${firstName}`,
      validFrom      : validFrom[0],
      validUntil     : validUntil[0],
      location       : locationReq ? locationReq.name : '-',
      roles          : rolesReq,
      credentials    : credentialsReq,
      pin            : pinReq,
      provider       : source
    }
  });

  const userWithCount = {
    users : users,
    page  : pageElement
  }
  
  return userWithCount;
}

export const getLocation = (location) => {
  return {
    id   : location.locationId,
    name : location.name
  };
}

export const getRoles = (roles) => {
  return roles.map(item => {
    return {
      name : item.name
    }
  });
}

export const getCredentials = (credentials) => {
  return credentials.map(item => {
    return {
      name : item.name
    }
  });
}

export const getUserApi = async (userId) => {
  const userResponse = await request({
    url     : `${USERS_AUTH_API}/${userId}`,
    method  : GET
  });
  
  const { user, events, location, roles, credentials, profiles }    = userResponse.data;
  const { id, attributes, firstName, lastName, username } = user;
  const source = attributes?.provider?.includes(USER_PROVIDER.LDAP) ? USER_PROVIDER.LDAP : USER_PROVIDER.LOCAL;
  
  const locations = {
    location : {
      locationId  : location.locationId,
      name        : location.name
    }
  }

  const userData = {
    userId         : id,
    firstName      : firstName,
    lastName       : lastName,
    personalNumber : username,
    validFrom      : attributes.validFrom[0],
    validUntil     : attributes.validUntil[0],
    data           : locations,
    events         : events,
    roles          : roles,
    credentials    : credentials,
    profiles       : profiles,
    pin            : attributes.pin ? attributes.pin[0] : '',
    provider       : source
  }
  
  return userData;
}

export const assignCredentials = async (credentialIds, id) => {
  await request({
      url     : `${api.CREDENTIALS}/assign-user`,
      method  : POST,
      data    : {
        userId: id,
        credentialIds: credentialIds
      }
    })
}

export const assignRoles = async (roleIds, id) => {
  await request({
      url     : api.USER_ROLES_USER_ASSIGN,
      method  : POST,
      data    : {
        userId: id,
        roleIds: roleIds
      }
    })
}

export const updateUserApi = async (id, values) => {
  const createUserResponse = await request({
    url    : `${USERS_AUTH_API}/${id}`,
    method : PUT,
    data   : values
  });

  return createUserResponse;
}

export const deleteUserApi = async (id) => {
  await request({
    url    : `${USERS_AUTH_API}/${id}`,
    method : DELETE
  });
}

export const detachAssocAndDeleteUser = async (id) => {
  await assignRoles([], id);
  await assignCredentials([], id);
  await UpdateUserEvents('', id);
  await deleteUserApi(id);

}

export const syncUserApi = async (userId) => {
  if (!userId) {
    return;
  }

  const syncUserResponse = await request({
    url    : api.SYNC_USER_ROLES,
    method : POST,
    data   : {
      id: userId
    }
  });

  return syncUserResponse;
}

export const createUserApi = async (values) => {
  const createUserResponse = await request({
    url    : USERS_AUTH_API,
    method : POST,
    data   : values
  });

  return createUserResponse;
}

export const assignUserToCredential = async (id, credentialIds) => {
  const assignUserCredentialsResponse = await request({
    url    : api.CREDENTIALS_USER_ASSIGN,
    method : POST,
    data   : {
      userId        : id,
      credentialIds : credentialIds
    }
  });

  return assignUserCredentialsResponse;
}

export const assignUserToRoles = async (id, roleIds) => {
  const assignUserRoleResponse = await request({
    url    : api.USER_ROLES_USER_ASSIGN,
    method : POST,
    data   : {
      userId  : id,
      roleIds : roleIds
    }
  });

  return assignUserRoleResponse;
}


export const getCredentialsByUserId = async (values) => {
  const responses = await multipleRequest(
    values.map(value =>
      request({
        url     : api.CREDENTIALS_BY_USER_ID,
        params : {
          userId : value.userId
        },
        method  : GET,
      })
    )
  );
  
  return responses;
}

export const exportUsers = async () => {
  const response = await request({
    url: api.USER_EXPORT,
    method: GET
  });

  const splitData = response.data.split(/\r?\n/).map(data => {
    const dataColumn = data.split(';');
    return {
      personalNumber: dataColumn[0],
      firstName     : dataColumn[1],
      lastName      : dataColumn[2],
      credential    : dataColumn[3],
      validFrom     : dataColumn[4],
      validUntil    : dataColumn[5],
      roleName      : dataColumn[6],
      location      : dataColumn[7]
    }
  });

  return splitData.slice(1, splitData.length-1);
}

export const GetUserWithPin = async (pin) => {
  const response = await request({
    url   : USERS_RESOURCE_API,
    method: GET,
    params: {
      q: `pin:${pin}`
    },
  });

  return response.data;
}

export const UpdateUserEvents = async (user, userId) => {
  const response = await request({
    url   : api.EVENTS,
    method: PATCH,
    data: {
      user: user,
      userId: userId
    },
  });

  return response.data;
}