import { Box, Typography } from '@material-ui/core';
import 'moment/min/locales';
import useStyles from "./styles";
import clsx from 'clsx';
import { ATTENTION_CARD_VARIANT } from '../../utility/constants';
import { useTranslation } from 'react-i18next';

const AttentionCard = (props) => {
  const { icon, description, variant } = props;
  const classes  = useStyles();
  const { t }    = useTranslation();

  return (
    <Box
      className={
        clsx(
          classes.reminderContainer,
          {
            [classes.yellowVariant] : variant === ATTENTION_CARD_VARIANT.YELLOW
          }
        )
      }
    >
      {icon}
      <Typography className={classes.reminderText}>
        {t(description)}
      </Typography>
    </Box>
  );
}

export default AttentionCard;