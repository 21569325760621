import React from 'react'
import useStyles from "./styles";
import { Box } from '@material-ui/core';
import { formatDate } from '../../utility/helper';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const TimeColumn = (props) => {
  const { value, multiLine = false } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const formattedDate = formatDate(value);
  // example: 17:20:39, Jul 05
  const timeDate = formattedDate.split(', ');
  if (timeDate.length > 1) {
    return (
      <>
        {
          multiLine ? 
            <Box className={classes.box}>
              <span className={classes.time}>{timeDate[0]}</span>
              <span className={classes.date}>{t(timeDate[1])}</span>
            </Box>
          :
            <Box className={classes.box}>
              <span className={clsx(classes.time)}>{t(timeDate[1])}{', '}{timeDate[0]}</span>
            </Box>
        }
      </>
    )
  }

  return (
    <Box className={classes.box}>
      {
        multiLine ?
          <>
            <span className={classes.time}>{formattedDate}</span>
            <span className={classes.date}>{t('events-page.today')}</span>
          </>
        :
          <span className={classes.time}>{`${formattedDate}, ${t('events-page.today')}`}</span>
      }
    </Box>
  )
}

export default TimeColumn