import { Box, Card, CardContent, Divider, Drawer, Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import EnhancedPopOver from "../../../components/popover";
import TimeColumn from "../../../components/time-column";
import api from "../../../service/api";
import { request } from "../../../service/requests";
import { ACCESS_POINT_MODULE, ANCHOR_STATE, API_REQUEST_ERROR_MESSAGE, CONTROLLERS_MODULE, DATA_TYPE, EVENT_KEY, GET, OFFLINE_STATUS, ONLINE, ONLINE_STATUS, STATUS } from "../../../utility/constants";
import { getEventTypeIcon, getSubTypeDescription } from "../../../utility/event";
import { getIndefiniteArticle } from "../../../utility/helper";
import EventSkeleton from "./events-drawer-skeleton";
import useStyles from "./styles";

const DetailsContainer = (props) => {
  const { data, isLoading } = props;
  const { title, fields, withNote } = data;
  
  const classes = useStyles();
  const {t}     = useTranslation();
  
  const getStatusCircle = (status) => {
    if (status === ONLINE_STATUS) {
      return <div className={clsx(classes.baseStatus, classes.onlineStatus)} />
    }
    return <div className={clsx(classes.baseStatus, classes.offlineStatus)} />
    
  }

  const getClickableDataDetails = (key, id, isDeleted) => {

    if ((key !== EVENT_KEY.USER && key !== EVENT_KEY.CREDENTIAL && key !== EVENT_KEY.ACCESS_POINT && key !== EVENT_KEY.NAME) || isDeleted) {
      return undefined;
    }

    let moduleUrlKey = `${key.toLowerCase()}s`;

    if (key === EVENT_KEY.NAME) {
      moduleUrlKey = CONTROLLERS_MODULE.toLowerCase();
    }

    if (key === ACCESS_POINT_MODULE) {
      moduleUrlKey = key.toLowerCase();
      moduleUrlKey = `${moduleUrlKey.replace(" p", "P")}s`
    }

    const url = `/${moduleUrlKey}/view/${id}`;
    window.open(url, '_blank');

  }

  const getDeleteLabel = (key) => {
    switch(key) {
      case EVENT_KEY.NAME:
        return t('events-page-side-panel.deletedControllerLabel');
      case EVENT_KEY.USER:
        return t('events-page-side-panel.deletedUserLabel');
      case EVENT_KEY.CREDENTIAL:
        return t('events-page-side-panel.deletedCredentialLabel');
      case EVENT_KEY.ACCESS_POINT:
        return t('events-page-side-panel.deletedAccessPointLabel');
      default:
        return '';
    }
  }

  return (
    <Card elevation={0} className={classes.card}>
      <CardContent>
        <Box>
          <span className={classes.cardTitle}>{title}</span>
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        <Box className={classes.fieldContainer}>
          {
            fields.map((field, index) => {
              const isLastField = index === fields.length-1;
              const isValueClickable = 
                field.key === EVENT_KEY.USER || 
                field.key === EVENT_KEY.CREDENTIAL || 
                field.key === EVENT_KEY.ACCESS_POINT || 
                field.key === EVENT_KEY.NAME;
              return (
                <Box className={clsx(isLastField && !withNote ? classes.lastFieldRow : classes.fieldRow)}>
                  <span className={classes.fieldKey}>
                    {field.translation}
                  </span>
                  {
                    isLoading ?
                      <EventSkeleton />
                    :
                      <span 
                        className={
                          clsx(
                            field.emphasize ? classes.emphasizeFieldValue : classes.fieldValue,
                            (!field.isDeleted && isValueClickable) && classes.presentEntity
                          )
                        }
                        onClick={() => getClickableDataDetails(field.key, field.id, field.isDeleted)}
                      >
                        <Box className={classes.valueContainer}>
                          {(field.key.toLowerCase()).includes(STATUS.toLowerCase()) && getStatusCircle(field.value)}
                          {field.value}
                        </Box>
                        {
                          field.isDeleted && 
                          <Tooltip 
                            title={getDeleteLabel(field.key)}
                            classes={{
                              tooltip: classes.deleteEntityTooltip
                            }}
                          >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                          </Tooltip>
                        }
                      </span>
                  }
                </Box>
              );
            })
          }
          {
            withNote ? 
              <>
                <Divider className={classes.noteDivider} />
                <Box className={classes.noteContainer}>
                  {
                    isLoading ?
                      <EventSkeleton width={2} />
                    :
                      <span className={classes.noteText}>{t('events-page-side-panel.controllerStateDescription')}</span>
                  }
                </Box>
              </>
            :
              null
          }
        </Box>
      </CardContent>
    </Card>
  );
}

const Content = (props) => {
  const { isLoading, eventDetail, closeEventDrawer, popoverState } = props;
  const { popoverAnchorEl, handlePopoverClick, handlePopoverClose } = popoverState;
  const { subType, type, dateAndTime, controller, event } = eventDetail;
  
  const {t}     = useTranslation();
  const classes = useStyles();

  const subTypeDescription = getSubTypeDescription(type, subType);

  const controllerData = {
    title: t('events-page-side-panel.controllerDetailsTitle'),
    fields: controller,
    withNote: true
  }
  
  let eventData = {
    title: t('events-page-side-panel.eventDetailsTitle'),
    fields: event,
    withNote: false
  }

  const controllerDetailsProps = {
    data: controllerData,
    isLoading
  }

  const eventDetailsProps = {
    data: eventData,
    isLoading
  }

  const popoverProps = {
    data        : {title: subType, description: subTypeDescription},
    popoverState: {anchorEl: popoverAnchorEl, handleClose: handlePopoverClose}
  }

  return (
    <Box className={classes.drawerContentContainer}>
      <Box className={classes.closeButtonContainer}>
        <CloseIcon className={classes.drawerCloseButton} onClick={() => closeEventDrawer()} />
      </Box>
      <Box>
        <Typography variant="h6" component="h6" className={classes.drawerTitle}>
          {
            isLoading ? 
              <EventSkeleton height={3} />
            :
              subType
          }
        </Typography>
        <Typography variant="subtitle1" className={classes.eventTime}>
          {
            isLoading ? 
              <EventSkeleton />
            :
              dateAndTime
          }
        </Typography>
        {
          isLoading ? 
            <Box className={classes.chipSkeletonContainer}>
              <EventSkeleton height={2} />
            </Box>
          :
            <Box className={classes.chipContainer}>
              {getEventTypeIcon(type)}
              <span className={classes.chipText}>
                {`${type} ${t('events-page-side-panel.event')}`}
              </span>
            </Box>
        }
        {
          controller.length ? 
            <DetailsContainer {...controllerDetailsProps} /> 
          : 
            <></>        
        }
        {
          event.length ?
            <DetailsContainer {...eventDetailsProps} />
          : 
            <></>
        }
        <Box className={clsx(subTypeDescription === ''  ? 'hidden' : classes.helperContainer)} onClick={(event) => handlePopoverClick(event)}>
          {
            isLoading ?
              <EventSkeleton />
            :
              <Trans i18nKey={getIndefiniteArticle(subType)} values={{eventType: subType}}/>
          }
        </Box>
        <EnhancedPopOver {...popoverProps}/>
      </Box>
    </Box>
  );
}

const EnhancedDrawer = (props) => {
  const {eventDrawerState, onClose, showToaster} = props;
  const { id, isOpen } = eventDrawerState;

  const {t} = useTranslation();

  const [isLoading, setIsLoading]     = useState(false);
  const [eventDetail, setEventDetail] = useState({
    subType    : '',
    type       : '',
    time       : '',
    location   : '',
    controller : [],
    event      : [],
  });
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const getDeviceStatus = (deviceStatus) => {
    return (deviceStatus === ONLINE || deviceStatus === 1) ? ONLINE_STATUS : OFFLINE_STATUS;
  }

  const getKeyWording = (key) => {
    switch(key) {
      case EVENT_KEY.LOCATION:
        return 'events-page-side-panel.locationLabel';
      case EVENT_KEY.CONTROLLER_NAME:
        return 'events-page-side-panel.nameLabel';
      case EVENT_KEY.CONTROLLER_FIRMWARE:
        return 'events-page-side-panel.firmwareLabel';
      case EVENT_KEY.CONTROLLER_STATUS:
        return 'events-page-side-panel.currentStatusLabel';
      case EVENT_KEY.CONTROLLER_SERIAL_NUMBER:
        return 'events-page-side-panel.serialNumberLabel';
      case EVENT_KEY.ACCESS_POINT:
        return 'events-page-side-panel.accessPointLabel';
      case EVENT_KEY.READER_NAME:
        return 'events-page-side-panel.readerLabel';
      case EVENT_KEY.READER_FIRMWARE:
        return 'events-page-side-panel.readerFirmwareLabel';
      case EVENT_KEY.READER_STATUS:
        return 'events-page-side-panel.currentReaderStatusLabel';
      case EVENT_KEY.USER:
        return 'events-page-side-panel.userLabel';
      case EVENT_KEY.CREDENTIAL:
        return 'events-page-side-panel.credentialLabel';
      default:
        return '';
    }
  }

  const getDataObject = useCallback((key, data, emphasize = false) => {
    if (!data || (!data[0] && !data[1])) {
      return null;
    }

    let value;
    let isDeleted = false;

    if (typeof data === DATA_TYPE.STRING) {
      value = data;
    } else if (!Array.isArray(data) || data.length === 0) {
      value = '';
    } else if (data[0]) {
      value = data[0];
      if (!data[1]) {
        isDeleted = true;
      }
    } else {
      value = data[1] || '';
      isDeleted = true;
    }
  
    return {
      key         : key,
      translation : t(getKeyWording(key)),
      value       : value,
      id          : typeof data === DATA_TYPE.STRING ? null : data[1],
      emphasize   : emphasize,
      isDeleted
    }
  }, [t])

  const getEvent = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await request({
        url     : `${api.EVENTS}/${id}`,
        method  : GET,
      })
      const { data: event } = response;
      const { 
        subType, 
        type, 
        dateCreated,
        location   : { name: locationName }                                                                       = {}, 
        controller : { name: controllerName, controllerId, status: controllerStatus, serialNumber: controllerSN, firmware } = {},
        accessPoint: { name: accessPointName, accessPointId }                                                     = {},
        reader     : { name: readerName, status: readerStatus, firmware: readerFirmware }                         = {},
        user       : { name: userName, userId }                                                                   = {},
        credential : { credentialId, credentialNumber }                                                           = {},
      }  = event;
  
      setEventDetail({
        subType            : subType,
        type               : type,
        dateAndTime        : <TimeColumn value={dateCreated} />,
        controller: [
          getDataObject(EVENT_KEY.LOCATION, locationName, true),
          getDataObject(EVENT_KEY.CONTROLLER_NAME, [controllerName, controllerId]),
          getDataObject(EVENT_KEY.CONTROLLER_SERIAL_NUMBER, controllerSN, true),
          getDataObject(EVENT_KEY.CONTROLLER_FIRMWARE, firmware, true),
          getDataObject(EVENT_KEY.CONTROLLER_STATUS, getDeviceStatus(controllerStatus), true),
        ].filter(field => field !== null),
        event:[
          getDataObject(EVENT_KEY.ACCESS_POINT, [accessPointName, accessPointId], false),
          getDataObject(EVENT_KEY.READER_NAME, readerName, true),
          getDataObject(EVENT_KEY.READER_FIRMWARE, readerFirmware, true),
          getDataObject(EVENT_KEY.READER_STATUS, readerName ? getDeviceStatus(readerStatus) : null, true),
          getDataObject(EVENT_KEY.USER, [userName, userId], false),
          getDataObject(EVENT_KEY.CREDENTIAL, [credentialNumber, credentialId], false) 
        ].filter(field => field !== null)
      })

    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [id, showToaster, t, getDataObject])

  useEffect(() => {
    if (id !== null) getEvent();
  }, [id, getEvent]);

  const contentProps = {
    eventDetail,
    closeEventDrawer: onClose,
    isLoading,
    popoverState: {
      popoverAnchorEl,
      handlePopoverClick,
      handlePopoverClose,
    }
  }

  return (
    <Drawer
      anchor={ANCHOR_STATE.RIGHT}
      open={isOpen}
      onClose={onClose}
    >
      <Content {...contentProps} />
    </Drawer>

  );
}

export default EnhancedDrawer