import { Container } from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EnhancedTable, { createColumn } from '../../components/enhanced-table';
import EventTypeColumn from '../../components/event-type-column';
import TimeColumn from '../../components/time-column';
import Title from '../../components/title';
import { EventsFilterContext } from '../../context/eventsFIlterContext';
import { LocationContext } from '../../context/locationContext';
import useParams from '../../hooks/useParams';
import { getAccessPoint } from '../../service/accessPointsApi';
import { getEventList, getEventListCount } from '../../service/eventsApi';
import { ACCESS_POINTS_MODULE, ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, CONTROLLERS_MODULE, DATETIME_FORMAT, DESCENDING, EVENTS_MODULE, EVENTS_TITLE, GET, GRANTED, NAME, USERS_MODULE } from '../../utility/constants';
import { formatDate, parseObjectParams, parseParams } from '../../utility/helper';
import EventsFilterModal from './events-filter';
import useStyles from './styles';
import EnhancedDrawer from './events-drawer';

const columns = [
  createColumn('id', 'ID', false, 'numeric', true),
  createColumn('time', 'events-page.timeColumn', true, 'component', false, true, true),
  createColumn('event', 'events-page.eventColumn', true, 'component', false, false, true),
  createColumn('controller', 'events-page.controllerColumn', true, 'string', false, false, false, CONTROLLERS_MODULE),
  createColumn('accessPoint', 'events-page.accessPointColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('reader', 'events-page.readerColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('user', 'events-page.userColumn', true, 'string', false, false, false, USERS_MODULE),
  createColumn('location', 'events-page.locationColumn', true, 'string'),
];

const Events = (props) => {
  const { showToaster, handlePermissions, newEvent, setNewEvent } = props;
  
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();

  const { state : stateFilter } = useContext(EventsFilterContext);
  const [searchParams, setSearchParams] = useParams(stateFilter);
  const hasAccessPointPermission = handlePermissions(ACCESS_POINTS_MODULE, GET);

  const { state : locationState }  = useContext(LocationContext);

  const { selectedLocationIds } = locationState;
  
  const { types, subTypes, from, until, deviceIds, accessPoint, credentialNumber, keyword: keywordParam,
    sort, listType: listTypeParam, size: sizeParam, page: pageParam
  } = searchParams;

  const [isLoading, setIsLoading]                     = useState(false);
  const [isTotalItemsLoading, setIsTotalItemsLoading] = useState(false);
  const [events, setEvents]                           = useState([]);
  const [openFilter, setOpenFilter]                   = useState(false);
  const [totalEvents, setTotalEvents]                 = useState(0);
  const [keyword, setKeyword]                         = useState(keywordParam);
  const [eventDrawerState, setEventDrawerState]       = useState({
    id    : null,
    isOpen: false
  });
  const [selectedRow, setSelectedRow]                     = useState(null);
  const [accessPointList, setAccessPointList]             = useState([]);
  const [currentGlobalLocation, setCurrentGlobalLocation] = useState(selectedLocationIds);

  let countCancelTokenRef = useRef(null); 
  let listCancelTokenRef = useRef(null); 
  let debounceTimeoutRef = useRef(null);
  
  const orderBy = parseParams(sort)[0];
  const order = parseParams(sort)[1];
  const listType = listTypeParam.toLowerCase();
  const size = parseInt(sizeParam);
  const page = parseInt(pageParam);
  
  const title = listType === GRANTED ? EVENTS_TITLE.ACCESS_GRANTED : EVENTS_TITLE.CONTROLLER_EVENTS; 

  const getTypesSubTypes = useCallback((values) => {
    const typeSubTypeParams = parseParams(values);
    const hasAllType = typeSubTypeParams.includes(ALL.toLowerCase()) ||
      typeSubTypeParams.includes(ALL);
      
    return hasAllType ? '' : typeSubTypeParams.join();
  }, []);

  const getDeviceIds = useCallback(() => {
    return parseObjectParams(deviceIds).map(item => item.name).join();
  }, [deviceIds]);

  const sortEvents = useCallback((eventA, eventB, order) => {
    const dateA = formatDate(eventA.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
    const dateB = formatDate(eventB.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
  
    if (dateA === dateB) {
      if (order === DESCENDING) {
        return eventA.eventId > eventB.eventId ? -1 : 1;
      } else if (order === ASCENDING) {
        if (eventA.type === eventB.type) {
          return eventA.eventId <= eventB.eventId ? -1 : 1;
        } else {
          return eventA.eventId > eventB.eventId ? -1 : 1;
        }
      }
    }
  
    return 0;
  }, []);

  const formatEvents = useCallback((event) => {
    const { eventId, type, subType, user, dateCreated, accessPoint, location, controller, reader } = event;
    return {
      id: eventId,
      time: <TimeColumn value={dateCreated} multiLine={true}/>,
      event:  <EventTypeColumn type={type} subType={subType}/>,
      controller: controller?.name,
      accessPoint: accessPoint?.name,
      reader: reader?.name,
      user: user?.name,
      location: location?.name
    };
  }, []);

  const getFilterParams = useCallback(() => {
    return {
      ...searchParams,
      types: getTypesSubTypes(types),
      subTypes: getTypesSubTypes(subTypes),
      deviceIds: getDeviceIds(),
      locationIds: selectedLocationIds.join()
    }
  }, [getTypesSubTypes, getDeviceIds, searchParams, selectedLocationIds, types, subTypes]);

  const getCountFilterParams = useCallback(() => {
    return {
      types: getTypesSubTypes(types),
      subTypes: getTypesSubTypes(subTypes),
      deviceIds: getDeviceIds(),
      accessPoint: accessPoint, 
      credentialNumber: credentialNumber, 
      keyword: keywordParam,
      until: until,
      from: from,
      locationIds: selectedLocationIds.join(),
    }
  }, [getTypesSubTypes, getDeviceIds, selectedLocationIds, types, subTypes, from, 
      until, accessPoint, credentialNumber, keywordParam]);

  const getEventsListCount = useCallback(async () => {
    setIsTotalItemsLoading(true);
    
    if (countCancelTokenRef.current) {
      countCancelTokenRef.current?.cancel();
    }
    countCancelTokenRef.current = axios.CancelToken.source();

    const filterParam = getCountFilterParams();
    try {
      const totalResponse = await getEventListCount(filterParam, countCancelTokenRef.current);
      setTotalEvents(totalResponse.page.totalElements);
      setIsTotalItemsLoading(false);
    } catch (error){
      if (!axios.isCancel(error)) {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setIsTotalItemsLoading(false);
      }
    }
  }, [t, showToaster, getCountFilterParams]);

  const getEventsData = useCallback(async () => {
    if (listCancelTokenRef.current) {
      listCancelTokenRef.current.cancel();
    }
    listCancelTokenRef.current = axios.CancelToken.source();

    const filterParam = getFilterParams();
    delete filterParam.listType;
    
    try {
      const response = await getEventList(filterParam, listCancelTokenRef.current);

      const eventList = response.events
        .sort((eventA, eventB) => sortEvents(eventA, eventB, order))
        .map(formatEvents);
        
      setEvents(eventList);
      setIsLoading(false);
    } catch (error){
      if (!axios.isCancel(error)) {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setIsLoading(false);
      }
    }
  }, [t, showToaster, getFilterParams, sortEvents, formatEvents, order]);

  const handleEventsData = useCallback(() => {
    setIsLoading(true);
    getEventsData();
  }, [getEventsData]);

  const handleWSMessage = useCallback(() => {
    if (!openFilter) {
      getEventsData();
      // Add 1 to the total count when there are new events, preventing an additional call
      setTotalEvents(prevCount => prevCount + 1);
    }
    setNewEvent(false);
  }, [getEventsData, openFilter, setNewEvent]);

  // Only fetch the count API when there are changes in searchParams except for page, size, and sort.
  useEffect(() => {
    getEventsListCount();
    return () => {
      setIsTotalItemsLoading(0);
    }
  }, [types, subTypes, from, until, deviceIds, accessPoint, credentialNumber, keywordParam, getEventsListCount]);

  useEffect(() => {
    handleEventsData();
    return () => {
      setEvents([]);
    }
  }, [searchParams, handleEventsData]);

  useEffect(() => {
    if (newEvent) {
      handleWSMessage();
    }
  }, [newEvent, handleWSMessage]);

  useEffect(() => {
    const isSameLocation = selectedLocationIds === currentGlobalLocation
    if (!isSameLocation ) {
      setSearchParams({...searchParams, page: 1});
      setCurrentGlobalLocation(selectedLocationIds);
    }
  }, [selectedLocationIds, searchParams, setSearchParams, currentGlobalLocation]);

  const handleFilterClose = () => {
    setOpenFilter(false);
  }

  const handleFilterOpen = () => {
    setOpenFilter(true);
  }

  const handleView = (row) => {
    history.push(`/events/view/${row.id}`);
  }

  const handleChangePage = (newPage) => {
    setSearchParams({...searchParams, page: newPage + 1});
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSearchParams({...searchParams, size: newRowsPerPage, page: 1});
  }

  const handleSort = (newOrderBy, newOrder) => {
    setSearchParams({...searchParams, sort: `${newOrderBy},${newOrder}`, page: 1});
  }

  const handleClearSearch = () => {
    setKeyword('');
    setSearchParams({...searchParams, keyword: '', page: 1});
  }

  const handleSearch = (value) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    
    setIsLoading(true);
    setKeyword(value);

    debounceTimeoutRef.current = setTimeout(() => {
      setSearchParams({...searchParams, keyword: value, page: 1});
    }, 1000);
  }

  const getAccessPoints = useCallback(async (devices) => {
    if (!hasAccessPointPermission) {
      return;
    }
  
    try {
      const response = await getAccessPoint('', '', 1, NAME, ASCENDING);
      const { accessPoints } = response;
  
      const newAccessPoints = accessPoints.map(accessPoint => {
        const { name, controller, location, id } = accessPoint;
        const deviceId = controller.deviceId;

        return {
          id          : id,
          name        : name,
          displayName : `${deviceId}:${name}`,
          locationId  : location.location_id
        }
      });
  
      let filteredAccessPointsList = newAccessPoints;

      if (selectedLocationIds.length) {
        filteredAccessPointsList = filteredAccessPointsList
          .filter(accessPoint => selectedLocationIds.includes(accessPoint.locationId));
      }

      if (devices?.length > 0) {
        filteredAccessPointsList = filteredAccessPointsList.filter(accessPoint =>
          devices.some(deviceId => accessPoint.displayName.includes(deviceId.name))
        );
      }
      
      setAccessPointList(filteredAccessPointsList);
    } catch (error){
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [showToaster, t, hasAccessPointPermission, selectedLocationIds]);

  const handleRowClick = (id) => {
    setEventDrawerState({
      id,
      isOpen: true
    })
    setSelectedRow(id);
  }

  const handleCloseDrawer = () => {
    setEventDrawerState({
      id: null,
      isOpen: false
    });
  };

  useEffect(() => {
    getAccessPoints();
  }, [getAccessPoints]);

  useEffect(() => {
    if (!eventDrawerState.isOpen) {
      setSelectedRow(null);
    }
  }, [eventDrawerState]);

  const eventDrawerProps = {
    eventDrawerState,
    showToaster,
    onClose: handleCloseDrawer
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <EnhancedDrawer {...eventDrawerProps} />
      <Title title={t(title)} listType={listType} />
    <EventsFilterModal
        open={openFilter}
        handleClose={handleFilterClose}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        handlePermissions={handlePermissions}
        accessPointList={accessPointList}
        getAccessPoints={getAccessPoints}
      />
      <EnhancedTable
        columns={columns}
        data={events}
        isLoading={isLoading}
        isTotalItemsLoading={isTotalItemsLoading}
        keyword={keyword}
        label={EVENTS_MODULE}
        module={EVENTS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onFilter={handleFilterOpen}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={size}
        totalItems={totalEvents}
        viewKey={"type"}
        listType={listType}
        handlePermissions={handlePermissions}
        handleRowClick={handleRowClick}
        selectedRow={selectedRow}
      />
    </Container>
  )
}

export default Events