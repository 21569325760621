import axios from "axios";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import EnhancedTable, { createColumn } from "../../../components/enhanced-table";
import EventTypeColumn from "../../../components/event-type-column";
import TimeColumn from "../../../components/time-column";
import { EventsFilterContext } from "../../../context/eventsFIlterContext";
import { LocationContext } from "../../../context/locationContext";
import useParams from "../../../hooks/useParams";
import { getEventList, getEventListCount } from "../../../service/eventsApi";
import { ACCESS_POINTS_MODULE, API_REQUEST_ERROR_MESSAGE, ASCENDING, CONTROLLERS_MODULE, DATETIME_FORMAT, DESCENDING, EVENTS_MODULE, USER_MODULE } from "../../../utility/constants";
import { formatDate, parseParams } from "../../../utility/helper";
import EnhancedDrawer from "../../events/events-drawer";

const columns = [
  createColumn('id', 'ID', false, 'numeric', true),
  createColumn('time', 'events-page.timeColumn', true, 'component', false, true, true),
  createColumn('event', 'events-page.eventColumn', true, 'component', false, false, true),
  createColumn('controller', 'events-page.controllerColumn', true, 'string', false, false, false, CONTROLLERS_MODULE),
  createColumn('accessPoint', 'events-page.accessPointColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('reader', 'events-page.readerColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('location', 'events-page.locationColumn', true, 'string'),
];

const UserEvents = (props) => {
  const { showToaster, handlePermissions, newEvent, setNewEvent, userId } = props;
  const { t }   = useTranslation();

  const { state : locationState } = useContext(LocationContext);
  const { selectedLocationIds }   = locationState;

  const { state : stateFilter }           = useContext(EventsFilterContext);
  const [ searchParams, setSearchParams ] = useParams(stateFilter);
  const { sort, page: pageParam }         = searchParams;

  const [isLoading, setIsLoading]                         = useState(false);
  const [isTotalItemsLoading, setIsTotalItemsLoading]     = useState(false);
  const [events, setEvents]                               = useState([]);
  const [totalEvents, setTotalEvents]                     = useState(0);
  const [selectedRow, setSelectedRow]                     = useState(null);
  const [currentGlobalLocation, setCurrentGlobalLocation] = useState(selectedLocationIds);
  const [eventDrawerState, setEventDrawerState]           = useState({
    id    : null,
    isOpen: false
  });

  let countCancelTokenRef = useRef(null); 
  let listCancelTokenRef  = useRef(null);
  
  const orderBy = parseParams(sort)[0];
  const order   = parseParams(sort)[1];
  const size    = 50;
  const page    = parseInt(pageParam);

  const sortEvents = useCallback((eventA, eventB, order) => {
    const dateA = formatDate(eventA.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
    const dateB = formatDate(eventB.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
  
    if (dateA === dateB) {
      if (order === DESCENDING) {
        return eventA.eventId > eventB.eventId ? -1 : 1;
      } else if (order === ASCENDING) {
        if (eventA.type === eventB.type) {
          return eventA.eventId <= eventB.eventId ? -1 : 1;
        } else {
          return eventA.eventId > eventB.eventId ? -1 : 1;
        }
      }
    }
  
    return 0;
  }, []);

  const formatEvents = useCallback((event) => {
    const { eventId, type, subType, dateCreated, accessPoint, location, controller, reader } = event;
    return {
      id: eventId,
      time: <TimeColumn value={dateCreated} multiLine={true}/>,
      event:  <EventTypeColumn type={type} subType={subType}/>,
      controller: controller?.name,
      accessPoint: accessPoint?.name,
      reader: reader?.name,
      location: location?.name
    };
  }, []);

  const userEventParams = useMemo(() => {
    return {
      ...searchParams,
      size,
      locationIds: selectedLocationIds.join(),
      userId: userId
    }
  }, [searchParams, selectedLocationIds, userId]);

  const getEventsListCount = useCallback(async () => {
    setIsTotalItemsLoading(true);
    
    if (countCancelTokenRef.current) {
      countCancelTokenRef.current?.cancel();
    }
    countCancelTokenRef.current = axios.CancelToken.source();
    try {
      const totalResponse = await getEventListCount(userEventParams, countCancelTokenRef.current);
      setTotalEvents(totalResponse.page.totalElements);
      setIsTotalItemsLoading(false);
    } catch (error){
      if (!axios.isCancel(error)) {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setIsTotalItemsLoading(false);
      }
    }
  }, [t, showToaster, userEventParams]);

  const getEventsData = useCallback(async () => {
    if (listCancelTokenRef.current) {
      listCancelTokenRef.current.cancel();
    }
    listCancelTokenRef.current = axios.CancelToken.source();
    
    try {
      const response = await getEventList(userEventParams, listCancelTokenRef.current);

      const eventList = response.events
        .sort((eventA, eventB) => sortEvents(eventA, eventB, order))
        .map(formatEvents);
        
      setEvents(eventList);
      setIsLoading(false);
    } catch (error){
      if (!axios.isCancel(error)) {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setIsLoading(false);
      }
    }
  }, [t, showToaster, sortEvents, formatEvents, order, userEventParams]);

  const handleEventsData = useCallback(() => {
    setIsLoading(true);
    getEventsData();
  }, [getEventsData]);

  const handleWSMessage = useCallback(() => {
    getEventsData();
    // Add 1 to the total count when there are new events, preventing an additional call
    setTotalEvents(prevCount => prevCount + 1);
    setNewEvent(false);
  }, [getEventsData, setNewEvent]);

  useEffect(() => {
    getEventsListCount();
    return () => {
      setIsTotalItemsLoading(0);
    }
  }, [getEventsListCount]);

  useEffect(() => {
    handleEventsData();
    return () => {
      setEvents([]);
    }
  }, [searchParams, handleEventsData]);

  useEffect(() => {
    if (newEvent) {
      handleWSMessage();
    }
  }, [newEvent, handleWSMessage]);

  useEffect(() => {
    const isSameLocation = selectedLocationIds === currentGlobalLocation
    if (!isSameLocation ) {
      setSearchParams({...searchParams, page: 1});
      setCurrentGlobalLocation(selectedLocationIds);
    }
  }, [selectedLocationIds, searchParams, setSearchParams, currentGlobalLocation]);

  const handleChangePage = (newPage) => {
    setSearchParams({...searchParams, page: newPage + 1});
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSearchParams({...searchParams, size: newRowsPerPage, page: 1});
  }

  const handleSort = (newOrderBy, newOrder) => {
    setSearchParams({...searchParams, sort: `${newOrderBy},${newOrder}`, page: 1});
  }

  const handleRowClick = (id) => {
    setEventDrawerState({
      id,
      isOpen: true
    })
    setSelectedRow(id);
  }

  const handleCloseDrawer = () => {
    setEventDrawerState({
      id: null,
      isOpen: false
    });
  };

  useEffect(() => {
    if (!eventDrawerState.isOpen) {
      setSelectedRow(null);
    }
  }, [eventDrawerState]);

  const eventDrawerProps = {
    eventDrawerState,
    showToaster,
    onClose: handleCloseDrawer
  }

  return (
    <>
      <EnhancedDrawer {...eventDrawerProps} />
      <EnhancedTable
        columns={columns}
        data={events}
        isLoading={isLoading}
        isTotalItemsLoading={isTotalItemsLoading}
        label={EVENTS_MODULE}
        module={USER_MODULE}
        onChangePage={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSort={handleSort}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={size}
        totalItems={totalEvents}
        viewKey={"type"}
        handlePermissions={handlePermissions}
        handleRowClick={handleRowClick}
        selectedRow={selectedRow}
        title={t('user-page.userEvents')}
      />
    </>
  )
}

export default UserEvents