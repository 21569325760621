import { BEARER, GET } from "../utility/constants";
import { formatLocationsSubAreas, nestedLocations } from "../utility/helper";
import api from "./api";
import { request } from "./requests";

export const getAdministratorAreaLocations = async (adminId, token) => {
  const response = await request({
    url   : `${api.AUTHORIZATIONS}/search/administrators/${adminId}`,
    method: GET,
    headers : {
      'Authorization': BEARER + token
    },
  });

  const { locations, subAreas } = response.data;
  const locationsAndSubAreas = locations.concat(subAreas);
  locationsAndSubAreas.sort((a, b) => a.name.localeCompare(b.name));
  const sortedLocations = formatLocationsSubAreas(locationsAndSubAreas);
  
  nestedLocations(sortedLocations);

  return sortedLocations;
}

export const getAdministratorLocations = async (adminId, token) => {
  const response = await request({
    url     : `${api.LOCATIONS_FIND_BY_ADMINISTRATOR_ID}/${adminId}`,
    method  : GET,
    headers : {
      'Authorization': BEARER + token
    },
  })

  return response.data;
}