import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/title';
import api from '../../service/api';
import { request } from '../../service/requests';
import { API_REQUEST_ERROR_MESSAGE, DATE_TIME_FORMAT, DEVICE_REQUEST, GET, INFO_ACCESS_POINT, INFO_CREDENTIAL_NUMBER, INFO_DEVICE, INFO_READER, INFO_RESPONSE_CODE, INFO_SERIAL_NUMBER, PATCH } from '../../utility/constants';
import { convertToUTC } from '../../utility/helper';
import LoadingState from './events-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes           = useStyles()
  const { t }             = useTranslation();
  const { initialValues, id } = props;

  return(
    <Formik
      initialValues={initialValues}
    >
      {
        formik => (
          <Box>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs>
                  <Typography id="eventType" variant="caption" className={'bold'} color="secondary">{t('event-page.type')}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography id="eventSubType" variant="caption" className={'bold'} color="secondary">{t('event-page.subType')}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography id="eventInfo1" variant="caption" className={'bold'} color="secondary">{t('event-page.info1')}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography id="eventInfo2" variant="caption" className={'bold'} color="secondary">{t('event-page.info2')}</Typography>
                </Grid>
                <Grid item xs className={!formik.values.readerFirmware && 'hidden'}>
                  <Typography id="eventReaderFirmware" variant="caption" className={'bold'} color="secondary">{t('event-page.readerFirmware')}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography id="eventDateCreated" variant="caption" className={'bold'} color="secondary">{t('event-page.dateCreated')}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs className={classes.boxBackground}>
                  <Typography id={`event${id}Type`} variant="caption">{formik.values.type}</Typography>
                </Grid>
                <Grid item xs className={classes.boxBackground}>
                  <Typography id={`event${id}SubType`} variant="caption">{formik.values.subType}</Typography>
                </Grid>
                <Grid item xs className={classes.boxBackground}>
                  <Typography id={`event${id}Info1`} variant="caption">{formik.values.infoOne}</Typography>
                </Grid>
                <Grid item xs className={classes.boxBackground}>
                  <Typography id={`event${id}Info2`} variant="caption">{formik.values.infoTwo}</Typography>
                </Grid>
                <Grid item xs className={formik.values.readerFirmware ? classes.boxBackground : 'hidden'}>
                  <Typography id={`event${id}ReaderFirmware`} variant="caption">{formik.values.readerFirmware}</Typography>
                </Grid>
                <Grid item xs className={classes.boxBackground}>
                  <Typography id={`event${id}DateCreated`} variant="caption">{formik.values.dateCreated}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )
      }
    </Formik>
  );
}

const Event = (props) => {
  const { t } = useTranslation();
  const { showToaster, match } = props;
  const path    = match.path;
  const { id }  = match.params;
  const classes = useStyles();
  const [isLoading, setIsLoading]             = useState(false);
  const [ contype, setConType ]               = useState('');
  const [ conSubType, setConSubType ]         = useState('');
  const [ conInfoOne, setConInfoOne ]         = useState('');
  const [ conInfoTwo, setConInfoTwo ]         = useState('');
  const [ conDateCreated, setConDateCreated ] = useState('');
  const [ readerFirmware, setReaderFirmware ] = useState('');

  const initialValues = {
    type          : contype,
    subType       : conSubType,
    dateCreated   : conDateCreated,
    infoOne       : conInfoOne,
    infoTwo       : conInfoTwo,
    readerFirmware: readerFirmware
  }

  useEffect(() => {
    if (id) {
      handleMarkAsRead();
      getEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfoTwo = (infoTwo) => {
    return infoTwo ? `${infoTwo} |` : '';
  }

  const getEvent = async () => {
    setIsLoading(true);
    try {
      const response = await request({
        url     : `${api.EVENTS}/${id}`,
        method  : GET,
      })
      const { dateCreated, subType, type, reader, accessPoint, credential, controller, additional }  = response.data
        let infoTwo;
        
        if (accessPoint) infoTwo = `${INFO_ACCESS_POINT}: ${accessPoint?.name}`;
  
        if (reader) infoTwo = `${getInfoTwo(infoTwo)} ${INFO_READER}: ${reader?.name}`;
  
        if (credential) infoTwo = `${getInfoTwo(infoTwo)} ${INFO_CREDENTIAL_NUMBER}: ${credential?.credentialNumber}`;
  
        if (type === DEVICE_REQUEST) infoTwo = `${INFO_RESPONSE_CODE}: ${additional}`;
  
        setConType(type);
        setConSubType(subType);
        setConInfoOne(`${INFO_DEVICE}: ${controller?.deviceId} | ${INFO_SERIAL_NUMBER}: ${controller?.serialNumber}`);
        setConInfoTwo(infoTwo);
        setConDateCreated(`${convertToUTC(dateCreated).format(DATE_TIME_FORMAT)}`);
        setReaderFirmware(reader?.firmware);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleMarkAsRead = async () => {
    try {
      await request({
        url     : `${api.EVENTS}/${id}`,
        method  : PATCH,
        data    : {
          isRead : 1
        }
      })
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t('event')} subtitle={`${contype}`}/>
      <Box className={classes.details}>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={'bold'} color="secondary">Details</Typography>
          </Grid>
        </Grid>
        {
          isLoading ?
            <LoadingState/>
          :
            <Content
              id={id}
              initialValues={initialValues}
              path={path}
            />
        }
      </Box>
    </Container>
  );
}

export default Event;